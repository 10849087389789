(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/use-previous/assets/javascripts/use-previous-query.js') >= 0) return;  svs.modules.push('/components/lb-utils/use-previous/assets/javascripts/use-previous-query.js');

'use strict';

const {
  useState,
  useEffect
} = React;

const usePreviousQuery = value => {
  const [cacheValue, setCacheValue] = useState(value);
  useEffect(() => {
    if (value.isSuccess || value.isError) {
      setCacheValue(value);
    }
  }, [value]);
  return cacheValue;
};
setGlobal('svs.components.lbUtils.usePrevious.usePreviousQuery', usePreviousQuery);

 })(window);