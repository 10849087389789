(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/use-previous/assets/javascripts/use-previous.js') >= 0) return;  svs.modules.push('/components/lb-utils/use-previous/assets/javascripts/use-previous.js');

'use strict';

const {
  useEffect,
  useRef
} = React;
const usePrevious = function (value) {
  let skip = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const ref = useRef();
  useEffect(() => {
    if (skip) {
      return;
    }
    ref.current = value;
  }, [value, skip]);
  return ref.current;
};
setGlobal('svs.components.lbUtils.usePrevious.usePrevious', usePrevious);

 })(window);